// Autogenerated using mud system-types
import BulkSetStateSystem from "../abi/BulkSetStateSystem.json";
import CombatSystem from "../abi/CombatSystem.json";
import ComponentDevSystem from "../abi/ComponentDevSystem.json";
import DropInventorySystem from "../abi/DropInventorySystem.json";
import EscapePortalSystem from "../abi/EscapePortalSystem.json";
import FactorySystem from "../abi/FactorySystem.json";
import GameConfigSystem from "../abi/GameConfigSystem.json";
import GatherResourceSystem from "../abi/GatherResourceSystem.json";
import Init1System from "../abi/Init1System.json";
import Init2System from "../abi/Init2System.json";
import MoveSystem from "../abi/MoveSystem.json";
import PlayerJoinSystem from "../abi/PlayerJoinSystem.json";
import PrototypeDevSystem from "../abi/PrototypeDevSystem.json";
import SpawnGoldDevSystem from "../abi/SpawnGoldDevSystem.json";
import TakeItemSystem from "../abi/TakeItemSystem.json";

export const SystemAbis = {
  "ember.system.bulkSetStateSystem": BulkSetStateSystem.abi,
  "ember.system.combat": CombatSystem.abi,
  "ember.system.componentDev": ComponentDevSystem.abi,
  "ember.system.dropInventory": DropInventorySystem.abi,
  "ember.system.escapePortal": EscapePortalSystem.abi,
  "ember.system.factory": FactorySystem.abi,
  "ember.system.gameConfig": GameConfigSystem.abi,
  "ember.system.gatherResource": GatherResourceSystem.abi,
  "ember.system.init1": Init1System.abi,
  "ember.system.init2": Init2System.abi,
  "ember.system.move": MoveSystem.abi,
  "ember.system.playerJoin": PlayerJoinSystem.abi,
  "ember.system.prototypeDev": PrototypeDevSystem.abi,
  "ember.system.spawnGoldDev": SpawnGoldDevSystem.abi,
  "ember.system.takeItem": TakeItemSystem.abi,
};
